import { DropDownListProps } from "@progress/kendo-react-dropdowns";
import React, { ComponentState, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getClientListData } from "./globalDataCheckerService";
import MultiSelectDropDown from "src/control-components/multi-select-drop-down/multi-select-drop-down";

export const ClientDropDownComponent = (props: clientDdlProps) => {
    const state: ComponentState = useSelector(states => {
        return states;
    });

    const { selectedClient, onChangeClient, isCheckActiveClient, switchClientValue,
        canRefresh, hideLabel } = props
    const { clientList } = state['DocumentReducer'];
    const [clientsList, setClientsList] = useState([]);
    const [isDataLoadStart, setDataLoadStart] = useState(false);
    const [loading, setLoading] = useState(false);


    useEffect(() => {

        if (!isDataLoadStart) {
            setLoading(true)
            callClientListApiData()
            setDataLoadStart(true)
        }
        if (clientList != null) {
            setLoading(false)
            setClientsList(clientList);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataLoadStart, clientList])

    const callClientListApiData = async () => {
        getClientListData(isCheckActiveClient, switchClientValue)
    }


    function renderToItem(li, itemProps) {
        const itemChildren = (
            <span>
                <input
                    type="checkbox"
                    name={itemProps.dataItem}
                    checked={itemProps.selected}
                    onChange={(e) => itemProps.onClick(itemProps.index, e)}
                />
                &nbsp;{li.props.children}
            </span>
        );
        return React.cloneElement(li, li.props, itemChildren);
    }

    return <div className="p-0 col-md-12">
        <div className="position-relative d-inline-block w-100">
            <MultiSelectDropDown
                data={clientsList}
                textField="name"
                label={hideLabel ? "" : "Client"}
                name="client"
                value={selectedClient}
                onChange={onChangeClient}
                autoClose={false}
                dataItemKey={"id"}
                itemRender={renderToItem}
                loading={loading}
            />
        </div>
    </div>
}

export interface clientDdlProps {
    selectedClient: any,
    isCheckActiveClient: boolean,
    switchClientValue: any,
    onChangeClient?: (newValue: DropDownListProps) => void,
    canRefresh: boolean,
    hideLabel: boolean,
}