
import $ from 'jquery';
import moment from "moment";

function converFileTemplate(fieldsMapping, documentId, viewMode, htmlFileName, index = 0) {

  // console.log("index", { documentId, index });
  if (viewMode) {
    // for show only

    let form = $(`.file-template`)
    // console.log("formList", form, htmlFileName)

    form = form.eq(index)
    // console.log("form", form)

    for (var i = 0; i < fieldsMapping.length; i++) {


      let type = form.find('input[name="' + fieldsMapping[i].keyName + '"]').attr('type');

      if (type == "checkbox" || type == "radio") {
        form.find('input[name="' + fieldsMapping[i].keyName + '"]').each(function () {
          if ($(this).val() == fieldsMapping[i].keyValue) {
            $(this).attr("checked", "checked")
          }
        })
      }
      else if (type == "time") {
        let newValue = moment(fieldsMapping[i].keyValue, "h:mm").format('LT')
        form.find('.file-' + documentId + ' [name="' + fieldsMapping[i].keyName + '"]').val(newValue);
        form.find('.file-' + documentId + ' .' + fieldsMapping[i].keyName).html(newValue);
        form.find('[name="' + fieldsMapping[i].keyName + '"]').hide();

        if (form.find('#span' + fieldsMapping[i].keyName).length <= 0) {
          form.find("#" + fieldsMapping[i].keyName).after("<span id='span" + fieldsMapping[i].keyName + "'>" + newValue + "</span>");
        }

      }
      else if (form.find('select[name="' + fieldsMapping[i].keyName + '"]').length > 0) {
        const selectElement = form.find('select[name="' + fieldsMapping[i].keyName + '"]');
        const selectedValue = fieldsMapping[i].keyValue;

        // Update the value and styles
        selectElement.val(selectedValue)
          .css('font-weight', '500')
          .css('color', '#000000')
          .css('max-height', '30px')
          .attr('disabled', 'disabled');

        // Explicitly set the selected attribute on the corresponding option
        selectElement.find('option').each(function () {
          if ($(this).val() === selectedValue) {
            $(this).attr('selected', 'selected');
          } else {
            $(this).removeAttr('selected');
          }
        });
      }

      else {
        var value = fieldsMapping[i].keyValue;
        var newValue = type === "date" ? value : value.charAt(0).toUpperCase() + value.slice(1);

        var elementSelector = '[name="' + fieldsMapping[i].keyName + '"]';
        var spanId = 'span' + fieldsMapping[i].keyName;
        var documentSelector = '.file-' + documentId;

        var existingSpan = form.find('#' + spanId);

        if (existingSpan.length < 1 && newValue !== "Invalid date") {
          form.find(elementSelector).after("<span id='" + spanId + "' class='view-only'>" + newValue + "</span>");
        }

        form.find(elementSelector).attr('id', fieldsMapping[i].keyName).hide();
        form.find(documentSelector + ' .' + fieldsMapping[i].keyName).html(newValue);
        form.find('.view-only').show();
      }
    }
  }
  else {
    if (fieldsMapping && fieldsMapping.length > 0) {
      // for save only
      for (var i = 0; i < fieldsMapping.length; i++) {
        let type = $('input[name="' + fieldsMapping[i].keyName + '"]').attr('type')
        // console.log("form", type)
        if (type == "checkbox" || type == "radio") {
          $('input[name="' + fieldsMapping[i].keyName + '"]').each(function () {
            if ($(this).val() == fieldsMapping[i].keyValue) {
              $(this).attr("checked", "checked")
            }
          })
        }
        else if (type == "date") {
          var now = new Date(fieldsMapping[i].keyValue);
          var day = ("0" + now.getDate()).slice(-2);
          var month = ("0" + (now.getMonth() + 1)).slice(-2);
          var today = now.getFullYear() + "-" + (month) + "-" + (day);
          $('input[name="' + fieldsMapping[i].keyName + '"]').val(today)
        }
        else {
          $('[name="' + fieldsMapping[i].keyName + '"]').val(fieldsMapping[i].keyValue)
          $('.view-only').hide();
          $('.add-only').show();
        }
      }
    }
  }
}

export function onShowFileTempFields(fieldsMapping, documentId, isViewMode, htmlFileName, index = 0) {
  return converFileTemplate(fieldsMapping, documentId, isViewMode, htmlFileName, index)
}

export function onShowFileValidations(setControlErrors) {
  let check = 0;
  let radioCheck = 0;
  let name = [];
  let arryName = [];
  let errors = []

  $('.form-control-input').each(function () {
    if ($(this).prop('required')) {

      var currentVal = $(this).val().length;
      if (currentVal <= 0) {
        errors.push({
          id: $(this).attr('id'),
          name: $(this).attr('name'),
          required: true,
          msg: $(this).attr('msg')
        })
      }
    }
  })

  $('.form-control-textarea').each(function () {
    if ($(this).prop('required')) {

      var currentVal = $(this).val();
      if (currentVal == "" || currentVal == null) {
        errors.push({
          id: $(this).attr('id'),
          name: $(this).attr('name'),
          required: true,
          msg: $(this).attr('msg'),
        })
      }
    }
  })

  $('.form-control-select').each(function () {
    if ($(this).prop('required')) {

      var currentVal = $(this).find('option:selected').val();
      if (currentVal <= 0) {

        errors.push({
          id: $(this).attr('id'),
          name: $(this).attr('name'),
          required: true,
          msg: $(this).attr('msg'),
        })
      }
    }

  });

  $('.form-control-checkbox').each(function () {
    if ($(this).prop('required')) {

      let nameVal = ($(this).attr('name'));
      if ($.inArray(nameVal, name) == -1) {
        name.push(nameVal);

        $("input:checkbox[name='" + nameVal + "']").each(function () {
          if ($(this).prop('checked')) {
            check = 1;
          }
        })

        if (check != 1) {

          errors.push({
            id: $(this).attr('id'),
            name: $(this).attr('name'),
            required: true,
            msg: $(this).attr('msg')
          })
        }
      }
    }
  })

  $('.form-control-radio').each(function () {
    if ($(this).prop('required')) {

      let nameVal = ($(this).attr('name'));
      if ($.inArray(nameVal, arryName) == -1) {
        arryName.push(nameVal);

        $("input:radio[name='" + nameVal + "']").each(function () {
          if ($(this).prop('checked')) {
            radioCheck = 1;
          }
        })
        if (radioCheck != 1) {

          errors.push({
            id: $(this).attr('id'),
            name: $(this).attr('name'),
            required: true,
            msg: $(this).attr('msg')
          })
        }
      }
    }
  })

  // Sorting helps to know which element needs the focus first if they are not filled.
  errors.sort((a, b) => a.id - b.id)

  setControlErrors(errors)
  return errors
}
