import { takeLatest, put } from 'redux-saga/effects';
import * as TYPES from '../actions/types';
import { SaveServiceSetDDL, loaderAction, saveStaffList, saveStaffNotificationSetting ,saveStaffNotificationMedium ,saveStaffCredentialList,getStaffCredentialList, saveStaffAuditLogList, saveStaffAuditLogMetaData, staffAuditLogListChangedAction, fetchStaffListAgainAction} from '../actions';
import { ServiceSetDDLResponse,DeleteStaffCredentialResponse,getStaffCredentialListResponse } from 'src/dataModels/staffResponsesModels';
import { staffServicess } from 'src/services/staffServices';
import { NotificationManager } from "react-notifications";
import NOTIFICATION_MESSAGE from 'src/helper/notification-messages';
import { StaffResponse } from 'src/dataModels/clientResponse';

export function* GetServiceSetDDLSaga() {
    yield takeLatest(TYPES.GET_SERVICE_SET_DDL, ServiceSetDDLWorker)
}
function* ServiceSetDDLWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ServiceSetDDLResponse = yield staffServicess.serviceSetDDL(param['payload'])
        yield put(SaveServiceSetDDL(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* GetStaffNotificationSettingSaga() {
    yield takeLatest(TYPES.GET_STAFF_NOTIFICATION_SETTING, GetStaffNotificationSettingWorker)
}
function* GetStaffNotificationSettingWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ServiceSetDDLResponse = yield staffServicess.getstaffNotificationSetting(param['payload'])
        yield put(saveStaffNotificationSetting(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* SaveStaffNotificationSaga() {
    yield takeLatest(TYPES.SAVE_STAFF_NOTIFICATION, GetStaffNotificationWorker)
}
function* GetStaffNotificationWorker(param: any) {
    try {
        yield put(loaderAction(true));
        yield staffServicess.savestaffNotification(param['payload'])
        NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* getStaffListSaga() {
    yield takeLatest(TYPES.GET_STAFF_LIST, staffListWorker);
}

function* staffListWorker() {
    try {
        // yield put(loaderAction(true));
        const response: StaffResponse = yield staffServicess.staffList();
        // yield put(loaderAction(false));
        yield put(saveStaffList(response.resultData));
    } catch (err) {
    }
}
export function* GetStaffNotificationMediumSettingSaga() {
    yield takeLatest(TYPES.GET_STAFF_NOTIFICATION_MEDIUM_SETTING, GetStaffNotificationMediumSettingWorker)
}
function* GetStaffNotificationMediumSettingWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: ServiceSetDDLResponse = yield staffServicess.getstaffNotificationMediumSetting(param['payload'])
        yield put(saveStaffNotificationMedium(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* SaveStaffNotificationMediumSaga() {
    yield takeLatest(TYPES.SAVE_STAFF_NOTIFICATION_MEDIUM_SETTING, SaveStaffNotificationMediumSagaWorker)
}
function* SaveStaffNotificationMediumSagaWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.saveStaffNotificationMediumSettings(param['payload'])
        if (response != undefined)
            NotificationManager.success(NOTIFICATION_MESSAGE.SUCCESS);
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* SaveStaffCredentialInfoSaga() {
    yield takeLatest(TYPES.SAVE_STAFF_CREDENTIAL_INFO, SaveStaffCredentialInfoWorker)
}
function* SaveStaffCredentialInfoWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.SaveStaffCredentialInfo(param['payload'])
        if (response != undefined)
            NotificationManager.success(NOTIFICATION_MESSAGE.ADD_CREDENTIAL);
        const staffId = param['payload']?.staffId;
        yield put(getStaffCredentialList(staffId));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* DeleteStaffCredentialSaga() {
    yield takeLatest(TYPES.DELETE_STAFF_CREDENTIAL, DeleteStaffCredentialWorker)
}
function* DeleteStaffCredentialWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: DeleteStaffCredentialResponse = yield staffServicess.DeleteStaffCredential(param['payload']?.deleteCredentialId)
        if (response.statusCode === 200) {

            NotificationManager.success(NOTIFICATION_MESSAGE.DELETE_CREDENTIAL);
        }
        const staffId = param['payload']?.staffId;
        yield put(getStaffCredentialList(staffId));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* GetStaffCredentialListSaga() {
    yield takeLatest(TYPES.GET_STAFF_CREDENTIAL_LIST, GetStaffCredentialListWorker)
}
function* GetStaffCredentialListWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: getStaffCredentialListResponse = yield staffServicess.getStaffCredentialList(param['payload'])
        yield put(saveStaffCredentialList(response));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* updateStaffCredentialSaga() {
    yield takeLatest(TYPES.UPDATE_STAFF_CREDENTIAL, updateStaffCredentialWorker)
}
function* updateStaffCredentialWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.updateStaffCredential(param['payload'])
        if (response.statusCode === 200) {

            NotificationManager.success(NOTIFICATION_MESSAGE.UPDATE_CREDENTIAL);
        }
        const staffId = param['payload']?.staffId;
        yield put(getStaffCredentialList(staffId));
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}


export function* getStaffAuditLogSaga() {
    yield takeLatest(TYPES.GET_STAFF_AUDIT_LOG_LIST, getStaffAuditLogWorker)
}
function* getStaffAuditLogWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.getStaffAuditLogList(param['payload'])
        if (response.statusCode === 200) {
            yield put(saveStaffAuditLogList(response.resultData));
            yield put(saveStaffAuditLogMetaData(response.metaData));
            yield put(staffAuditLogListChangedAction(false));
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* assignAdditionalClientsToStaffSaga() {
    yield takeLatest(TYPES.ASSIGN_ADDITIONAL_CLIENTS_TO_STAFF, assignAdditionalClientsToStaffWorker)
}
function* assignAdditionalClientsToStaffWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.assignAdditionalClientsToStaff(param['payload'])
        if (response.statusCode === 200) {
           NotificationManager.success("Clients assigned successfully")
           yield put(fetchStaffListAgainAction(true))
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}

export function* assignStaffToSiteSaga() {
    yield takeLatest(TYPES.ASSIGN_STAFF_TO_SITE, assignStaffToSiteWorker)
}
function* assignStaffToSiteWorker(param: any) {
    try {
        yield put(loaderAction(true));
        const response: any = yield staffServicess.assignStaffToSite(param['payload'])
        if (response.statusCode === 200) {
           NotificationManager.success("Site assigned successfully")
           yield put(fetchStaffListAgainAction(true))
        }
        yield put(loaderAction(false));
    }
    catch (err) {
        yield put(loaderAction(false));

    }
}